.org-tree {
    padding: 0;
}

.org-tree ol {
    display: flex;
    flex-direction: row;
    padding: 0;

}

.org-tree li::before,
.org-tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
}
.org-tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}
.org-tree li:only-child::after,
.org-tree li:only-child::before {
    display: none;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
    border: 0 none;
}

.org-tree li:last-child::before {
    border-right: 1px solid #ccc;
}
.org-tree li:last-child::before {
    border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
    border-radius: 5px 0 0 0;
}
